.nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 20px;
    height: 30px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s;
    transition-timing-function: ease-in;
  }
  .nav_black {
    background-color: #111;
  }
  .nav_logo {
    position: fixed;
    left: 20px;
    width: 80px;
    object-fit: contain;
  }
  .nav_avatar {
    position: fixed;
    right: 20px;
    width: 30px;
    object-fit: contain;
  }